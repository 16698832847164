import React from "react";
import { Link } from "react-router-dom";
import { Translate, Localize, translate } from "react-i18nify";
import styled from "styled-components";
import db from "./db";
import Spinner from "./styled/Spinner";

const Table = styled.td`
  width: 0;
`;

const DateCell = styled.td`
  padding: 0.25em 1em 0.25em 0;
  white-space: nowrap;
`;

const DomainCell = styled.td`
  padding: 0.25em;
  font-weight: bold;
  white-space: nowrap;

  a {
    &,
    &:link,
    &:visited {
      color: #333;
    }
  }
`;

class ResultList extends React.Component {
  constructor() {
    super();
    this.state = {
      results: null,
    };
  }

  componentDidMount() {
    this.getResults();
    document.title = `${translate("application.menu.results")} | ${translate(
      "application.title"
    )}`;
  }

  async getResults() {
    this.setState({
      results: db.list(),
    });
  }

  render() {
    if (this.state.results) {
      return this.state.results.length > 0 ? (
        <Table>
          <tbody>
            {this.state.results.map((item) => (
              <tr key={item.id}>
                <DateCell>
                  <Localize value={item.date} dateFormat="date" />
                </DateCell>
                <DomainCell>
                  <Link to={`/result/${item.id}`}>{item.domain}</Link>
                </DomainCell>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <Translate value="application.result.noTests" />
      );
    }
    return <Spinner size={50} text="application.result.loading" />;
  }
}

export default ResultList;
