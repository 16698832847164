import React from "react";
import ReactDOM from "react-dom";
import {
  setTranslations,
  setLocale,
  addLocale,
  translate,
} from "react-i18nify";
import cs from "date-fns/locale/cs";
import en from "date-fns/locale/en-US";
import { translations, getLocale } from "./lang";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import App from "./App";
import config from "./config.json";
import * as Sentry from "@sentry/react";
import { piwik, trackAtConnect } from "./piwik";

Sentry.init({
  dsn: config.sentryDSN,
});

setTranslations(translations);
setLocale(getLocale());
addLocale("en", en);
addLocale("cs", cs);

const history = createBrowserHistory();

document.addEventListener("DOMContentLoaded", () => {
  document.documentElement.lang = getLocale();
  document.title = translate("application.title");
});

ReactDOM.render(
  <React.StrictMode>
    <Router
      basename={process.env.PUBLIC_URL}
      history={piwik.connectToHistory(history, trackAtConnect)}
    >
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
