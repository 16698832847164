const key = 'zm-results';

function getResults() {
  const results = JSON.parse(localStorage.getItem(key));
  if (results === null) {
    return [];
  }
  return results;
}

const dbApi = {
  add: (result) => {
    localStorage.setItem(key, JSON.stringify([...getResults(), result]));
    return result;
  },

  count: () => getResults().length,

  list: () => getResults()
};

export default dbApi;
