import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Translate, translate, setLocale } from 'react-i18nify';
import styled from 'styled-components';
import TestResult from './TestResult';
import TestForm from './TestForm';
import ResultList from './ResultList';
import Header from './Header';
import Footer from './Footer';
import config from './config.json';
import { getLocale } from './lang';

const AppContainer = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font: 16px/1.5em sans-serif;
`;

const AppContent = styled.div`
  flex: 1;
  padding: 2rem calc(1rem + 10vw);
  display: flex;
  flex-direction: column;

  @media (max-width: 700px) {
    padding: 2rem 1rem;
  }
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: getLocale()
    };
    this.switchLang = this.switchLang.bind(this);
  }

  switchLang(lang) {
    setLocale(lang);
    this.setState({ lang });
  }

  render() {
    return (
      <AppContainer>
        <Header
          title={translate('application.title')}
          beta={config.beta}
          lang={this.state.lang}
          switchLang={this.switchLang}
        />
        <AppContent>
          <Switch>
            <Route exact path='/' component={TestForm} />
            <Route exact path='/result' component={ResultList} />
            <Route
              path='/result/:id'
              render={(routeProps) => (
                <TestResult 
                {...routeProps}
                lang={this.state.lang}
                />
              )}
            />
            <Route render={() => <Translate value='pageNotFound' />} />
          </Switch>
        </AppContent>
        <Footer config={config.footer} />
      </AppContainer>
    );
  }
}

export default App;
