import ReactPiwik from 'react-piwik';
import config from './config.json';

const piwik = new ReactPiwik({
  url: config.piwik.url,
  siteId: config.piwik.id
});

ReactPiwik.push(["disableCookies"]);

const trackAtConnect = true;

export { piwik, trackAtConnect };
