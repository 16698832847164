import en from './translations/en.json';
import cs from './translations/cs.json';

const translations = { cs, en };
const supportedLangs = Object.keys(translations);
const getLocale = () => {
  const browserLang = window.navigator.userLanguage || window.navigator.language;
  if (['cs', 'sk'].indexOf(browserLang) > -1) {
    return 'cs';
  }
  return 'en';
};
export { translations, supportedLangs, getLocale };
